.badge-check{
  width: 20px;
  height: 20px;
  margin-top: -3px;
  margin-left: 7px;
}

.filtro-exercicios{
  position: relative !important;
  z-index: 3;
  color: #fff;
  width: 40% !important;
  button{
    background-color: #fff;
    color: #ff914d;
    border: 1px solid #ff914d!important;
    border-radius: 20px;
  }
  button:hover{
    background-color: #ff914d;
    color: #fff;
  }
  button:focus{
    background-color: #ff914d!important;
    color: #fff;
  }
  .active{
    background-color: #ff914d!important;
  }
}

.animation-not-atv{
  color: #ff914d;
  text-align: center;
  div{
    margin-top: -10vh;
    height: 60vh;
  }
}

.lst-atividade{
  max-height: 60vh;
  border:1px solid #dee2e6;
  border-radius: 6px;
  li{
    border: initial;
    border-bottom: 1px solid #dee2e6;
  }
  li:nth-last-child(1){
    border-bottom: initial!important;
  }
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px!important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ff914d!important;
    border-radius: 6px;
  }
  
  
  &::-webkit-scrollbar-track {
    background-color: lightgray!important;
    border-radius: 6px;
  }
  
  &::-webkit-scrollbar-button {
    display: none!important;
  }
}

@media (max-width: 767px) {
  .filtro-exercicios{
    margin-top: -15px!important;
    width: 50%!important;
    button{
      width: 50%;
    }
  }
  .lst-atividade{
    border:1px solid #dee2e6;
    border-radius: 6px;
    max-height: 54vh;
    li{
      border: initial;
      border-bottom: 1px solid #dee2e6;
    }
    li:nth-last-child(1){
      border-bottom: initial!important;
    }
  }
}