
.cm-editor{
  min-height: 258px;
}

.btns-send-atv{
  button{
    position: relative;
    float: right;
  }
  button:nth-child(2){
    margin-right: 15px;
  }
  button:nth-child(3){
    float: left !important;
  }
}

.list-log-tests{
  list-style: none;
  padding-left: 0px !important;
  font-size: 12px;
  .error{
    color: red;
  }
  .success{
    color: rgb(95, 233, 95);
  }
}

.content-atividade{
  margin-bottom: 15vh;
}

.animation-parabens{
  position: fixed;
  height: 100vh!important;
  width: 100vw!important;
  left: 0px;
  top: 0px;
}

@media (max-width: 767px) {
  .col-atv{
    width: 100%;
    position: relative;
    left: 53%;
    transform: translateX(-50%);
  }

  .animation-parabes{
    top: 15vh;
  }

  .cm-editor{
    min-height: 258px;
    font-size: 14px;
  }
}