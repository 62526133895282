.l-code-navbar{
  background: linear-gradient(to right, #ff3131 , #ff914d 60%);
  box-shadow: 0 2px 2px gray;
  height: 10vh;
  .navbar-brand{
    width: 100%;
    .btn.show, .btn:active, .btn{
      border: 0px!important;
    }
    .dropdown {
      position: relative;
      float: right;
    }
    .dropdown-toggle {
      &::after {
        display: none !important;
      }
    }
    .dropdown-menu[data-bs-popper] {
      right: 0px !important;
      left: initial!important;
      svg{
        color: #ff914d;
        margin-top: 4px;
        right: 15px;
        position: absolute;
      }
    }
  }
  .logo-svg{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 11.5vh;
    width: auto;
    margin-left: 5px;
  }
  .foto-perfil{
    position: relative;
    margin-right: -15px;
    float: right;
    height: 9vh;
    width: auto;
    border: 3px solid #fff;
  }
}
.l-code-content{
  min-height: 80vh;
  div{
    padding: 10px;
  }
}
.menu{
  display: block;
  width: 100%;
  position: fixed;
  cursor: pointer;
  bottom: 0px;
  text-align: center;
  min-height: 8vh;
  box-shadow: 4px 5px 2px 6px #ff914d;
  background-color: #fff;
  div{
    color: #ff914d;
    // border: 2px solid #ff914d;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      text-align: center;
      position: absolute;
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 767px) {
  .div-centralizada {
    justify-content: center;
  }
}

@media screen and (max-height: 500px) {
  .menu{
    display: none!important;
  }
}