@import '~bootstrap/scss/bootstrap';

html, body {
  overflow-x: hidden;
  overflow-y: auto;
}

.text-red{
  color: #ff3131!important;
}

.cursor-pointer{
  cursor: pointer;
}

.btn-lcod{
  background-color: #fff;
  color: #ff914d;
  border: 1px solid #ff914d!important;
  &:hover{
    background-color: #ff914d;
    color: #fff;
  }
  &:focus{
    background-color: #ff914d!important;
    color: #fff;
  }
  .active{
    background-color: #ff914d!important;
  }
}