.description{
  div{
    .title{
      margin-top: 20px;
      height: 1px;
      width: 80vw;
      background-color: #ff914d;
      padding: 0px;
      margin-bottom: 15px;
      span{
        position: relative;
        background-color: white;
        color: #ff914d;
        top: -0.8em;
        padding: 0 10px;
        font-size: 25px;
        margin-left: 20px;
      }
      .sm{
        font-size: 20px;
      }
    }
  }
}

.margin{
  margin-top: 100px!important;
}

@media (max-width: 767px) {
  .description{
    div{
      .title{
        width: 90vw;
      }
    }
  }
}