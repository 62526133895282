.modal-header{
    color: #fff;
    background-color: #ff914d;
}
.modal-body{
    font-size: 14px;
    .diretorio{
        b:nth-child(1){
            color: rgb(122, 168, 52);
        }
        b:nth-child(2){
            color: rgb(44, 44, 192);
        }
    }
}