.load{
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background-color: #ffffff87;
  div{
    width: 100px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}