.container-login{
  padding: 0px!important;
  margin: 0px!important;
}
.triangle-container {
  width: 100%;
  height: 80vh;
  background: linear-gradient(to top, #ff3131 , #ff914d 60%);
  &::before, &::after {
    content: "";
    position: absolute;
    background-color: #fff;
    bottom: 20%;
    height: 10vw;
    width: 50%;
  }
  
  &::before {
    left: 0;
    transform: skewY(10deg);
    transform-origin: bottom left;
  }
  &::after {
    right: 0;
    transform: skewY(-10deg);
    transform-origin: bottom right;
  }

  .logo{
    position: absolute;
    left: 50%;
    transform: translateX(-51%);
    top: 10%;
    color: #fff;
    border: 3px solid #fff;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    text-align: center; 
    .shadow-logo{
      position: relative;
      top: 3%;
      left: 50%;
      width: 47%;
      height: 94%;
      border-radius: 0 100px 100px 0;
      background-color: #fff;
      opacity: 0.5;
    }
    h3{
      position: relative;
      top: -80px;
    }

    
  }
  .login-form{
    position: absolute;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    left: 0.5%;
    z-index: 2;
    box-shadow: 0 3px 5px gray;
    margin-top: 40vh;
    padding: 30px;
    form{
      opacity: 1!important;
      z-index: 3;
      .login-password{
        svg{
          position: relative;
          left: 93%;
          top: -30px;
          color: #ff914d;
          cursor: pointer;
        }
      }
      button{
        width: 100%;
        background-color: #ffF;
        color: #ff914d;
        border: 2px solid #ff914d;
        border-radius: 20px;
        border: 2px solid #ff914d;
      }
      button:hover{
        background-color: #ff914d;
        color: #fff;
        transition: 0.5s;
        border: 2px solid #ff914d;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .triangle-container {
    background: linear-gradient(to top, #ff3131 , #ff914d 90%);
    &::before, &::after {
      bottom: 10.5%;
      height: 30vw;
    }
    
    &::before {
      transform: skewY(25deg);
    }
    &::after {
      transform: skewY(-25deg);
    }
    .logo{
      top: 8%;
    }
    .login-form{
      left: 0px;
      margin-top: 29vh;
      form{
        .login-password{
          svg{
            left: 90%;
          }
        }
        button:hover{
          transition: 0s;
        }
      }
    }
  }
 }

@media screen and (max-height: 500px) {
  .logo{
    width: 130px!important;
    height: 130px!important;
    top: 4%!important;
    h3{
      top: -70px!important;
    }
  }
}

